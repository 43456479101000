<template>
  <b-overlay :show="loading" rounded>
    <header v-if="myGudang">
      <h5>
        <strong>Lokasi Gudang: {{ this.myGudang.nama_gudang }}</strong>
      </h5>
    </header>
    <b-row>
      <b-col md="3" sm="12">
        <p><strong>Tanggal</strong></p>
        <p class="mb-2">{{ so.tanggal }}</p>
        <section class="mt-1" v-if="so.gm">
          <p><strong>General Manager</strong></p>
          <p>
            {{ so.gm.nama_lengkap }}
          </p>
        </section>
      </b-col>
      <b-col md="3" sm="12">
            <p><strong>No</strong></p>
            <p>{{ so.no }}</p>
          </b-col>
          <b-col md="3" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 0">
            <p><strong>Status</strong></p>
            <b-badge variant="light-danger" size="sm"
              >Belum Selesai</b-badge
            >
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 1">
            <p><strong>Status</strong></p>
            <b-badge variant="light-success" size="sm"
              >Selesai</b-badge
            >
          </b-col>
      <!-- Info Barang -->
      <!-- / -->
      </b-row>

      <section v-if="isGM && !so.id_gm" class="d-flex justify-content-end my-2">
        <b-button variant="primary" size="sm" @click.prevent="approve">Approve Stok Opname</b-button>
      </section>

      <b-row>
      <!-- Input Stock Opname -->
      <b-col cols="12">
        <b-card style="overflow-y: auto; max-height: 500px">
          <b-table small bordered striped hover responsive :fields="fields" :items="items" class="mb-2">
            <template #cell(checkbox)="{item, index}">
              <b-form-checkbox @change="updatePenyesuaian(index)" v-model="item.checked"></b-form-checkbox>
            </template>
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(so)="{ item }">
              {{ item.id_stok_opname }}
            </template>
            <template #cell(selisih)="{ item }">
              {{ item.jumlah ? item.jumlah - item.stok : 0 }}
            </template>
            <template #cell(penyesuaian)="{item}">
              <b-form-input v-if="item.checked" v-model="item.penyesuaian"></b-form-input>
              <span v-else>{{ item.penyesuaian }}</span>
            </template>
          </b-table>
        </b-card>
        <section class="my-2 d-flex justify-content-end" v-if="alreadyApproved">
          <b-button variant="primary" @click.prevent="submit">Simpan Penyesuaian</b-button>
        </section>
      </b-col>
      <!-- / -->
    </b-row>
    <footer class="d-flex justify-content-end">
      <b-button v-if="so.status == 0" variant="primary" @click.prevent="save">Simpan</b-button>
    </footer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
    BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
  },
  data() {
    return {
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan_id: [],
      jumlah: 0,
      stok: 0,
      id_stok_opname: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "detail.nama",
          label: "Nama Barang",
          sortable: true
        },
        {
          key: "detail.blok",
          label: "Blok",
          sortable: true
        },
        {
          key: "detail.palet",
          label: "palet",
        },
        {
          key: "detail.rak",
          label: "rak",
        },
        {
          key: "detail.laci",
          label: "laci",
        },
        {
          key: "stok",
          label: "Stok",
        },
        {
          key: "detail.satuan",
          label: "satuan",
        },
        {
          key: "jumlah",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        },
        {
          key: 'penyesuaian',
          label: 'Penyesuaian GM'
        }
      ],
      items: []
    };
  },
  async mounted() {
    this.getRincian()
    await this.getDataSo()
    this.setField()
    if (this.$route.params.id) {
      this.id_stok_opname = this.$route.params.id;
    }
  },
  computed: {
    alreadyApproved() {
      return this.isGM && this.so.id_gm
    }
  },
  methods: {
    async submit() {
      const payloads = this.items.map(item => ({
        id: item.id,
        id_stok_opname: this.$route.params.id,
        id_penyimpanan: item.id_penyimpanan,
        stok: item.stok,
        jumlah: item.jumlah,
        penyesuaian: item.penyesuaian
      }))

      try {
        this.loading = true
        await this.$store.dispatch('stokopname/saveSo', payloads)
        await this.displaySuccess({
          message: 'Stok Opname berhasil disimpan'
        })
        await this.getDataSo()
        this.loading = false
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    updatePenyesuaian(index) {
      this.items[index].penyesuaian = this.items[index].jumlah - this.items[index].stok
    },
    setField() {
      if(this.alreadyApproved) {
        this.fields = [
          {
            key: 'checkbox',
            label: '#'
          },
          {
            key: "no",
            label: "No",
          },
          {
            key: "detail.nama",
            label: "Nama Barang",
            sortable: true
          },
          {
            key: "detail.blok",
            label: "Blok",
            sortable: true
          },
          {
            key: "detail.palet",
            label: "palet",
          },
          {
            key: "stok",
            label: "Stok",
          },
          {
            key: "detail.satuan",
            label: "satuan",
          },
          {
            key: "jumlah",
            label: "Hasil Stok Opname",
          },
          {
            key: "selisih",
            label: "selisih barang",
          },
          {
            key: 'penyesuaian',
            label: 'Penyesuaian'
          }
        ]
      }
    },
    approve() {
      this.$swal({
        title: 'Peringatan',
        text: 'Dengan menyetujui stock opname ini, anda dapat melakukan penyesuaian hasilnya',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false
      })
      .then(async res => {
        if(res.value) {
          const payload = {
            id: this.$route.params.id,
            id_gudang: this.so.id_gudang,
            tanggal: this.so.tanggal,
            id_karyawan: this.so.id_karyawan,
            id_gm: this.user.karyawan ? this.user.karyawan.id : null
          }

          try {
            this.loading = true

            await this.$store.dispatch('stokopname/save', [payload])
            await this.getDataSo()
            this.displaySuccess({
              message: 'Stok opname sudah disetujui!'
            })

            this.loading = false
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
        }
      })
    },
    resetform() {
      this.item.jumlah = 0;
      this.jumlah = 0;
    },
    async save() {
      if (this.id) {
        this.id = this.id
      }
      let payloads = []
      this.penyimpanan_id.map(item => {
        payloads.push({
          id_stok_opname: this.$route.params.id,
          id_penyimpanan: item.id,
          stok: item.stok,
          jumlah: item.jumlah
        })
      })
      let payload1 = {
        status: this.status
      }
      if (this.$route.params.id) {
            payload1.id = this.$route.params.id;
          }
      try {
        this.loading = true
        await this.$store.dispatch('stokopname/saveSo', payloads)
        await this.$store.dispatch('stokopname/save', [payload1])
        this.loading = false

        this.displaySuccess({
          message: 'Stok Opname berhasil disimpan!'
        })

        this.resetForm();
        this.getRincian();

      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    getRincian() {
      this.$store
      .dispatch("stokopname/getDataSo", { id_stok_opname: this.$route.params.id })
      .then(() => {
        this.items = JSON.parse( JSON.stringify(this.$store.state.stokopname.datas) );
        this.items.map(item => item.checked = false)
        this.totalRows = this.items.length;
      })
      .catch((e) => {
        this.displayError(e);
        return false;
      });
    },
    async getDataSo() {
      const so = await this.$store
        .dispatch("stokopname/getDataById", this.$route.params.id);
          this.so = so;
    }
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          jumlah: 0,
          isFinalStock: false,
        });
      }
    },
  },
};
</script>
